<template>
  <div id="wrap">
    <loading/>
    <header-area></header-area>
    <keep-alive
        v-if="!isInDashBoard"
        :include="/List\b/" :max="1">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
<!--    <floating v-if="!(['25','35'].includes($store.getters.loginUserData.authCd))"></floating>-->
    <footer-area></footer-area>
  </div>
</template>

<script>
import HeaderArea from '../components/HeaderArea'
import FooterArea from '../components/FooterArea'
import Loading from '@/components/Loading'

export default {
  name: 'MainLayout',
  components: {
    Loading,
    FooterArea,
    HeaderArea,
  },
  watch: {
    $route (to, from){
      this.isInDashBoard = to.name === 'DashBoard';
    }
  },
  created: function() {
    this.$store.dispatch('initSearchDataForCodeRequest')
  },
  data() {
    return {
      isInDashBoard: true
    }
  },
}
</script>

<style scoped>
</style>
